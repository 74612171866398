const LogoIcon = ({width = 109, height = 30}) => (
	<svg
		className="fill-current text-slate-600"
		viewBox="0 0 109 30"
		width={width}
		height={height}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M90.7403 6.85266V7.46821C90.7403 8.11454 90.5864 8.60698 90.3094 9.16097C90.1863 9.46875 89.9709 9.83808 90.2786 9.99196C90.5864 10.1458 90.7711 9.74574 90.8326 9.62263C91.8175 7.8991 93.4487 6.60645 95.9725 6.60645C100.189 6.60645 103.051 10.2997 103.051 14.9779C103.051 19.6561 100.189 23.3494 95.9725 23.3494C93.5103 23.3494 91.8175 22.0875 90.8326 20.3024C90.7711 20.2101 90.648 19.9023 90.371 19.9946C90.0324 20.1177 90.1555 20.4255 90.3094 20.7333C90.5864 21.2873 90.7403 21.7797 90.7403 22.3953V29.0124H86.8008V6.85266H90.7403ZM95.0491 19.7792C97.8191 19.7792 99.1118 17.5632 99.1118 14.9779C99.1118 12.3926 97.8191 10.1766 95.0491 10.1766C92.4638 10.1766 90.7403 12.3926 90.7403 14.9779C90.7403 17.5632 92.4638 19.7792 95.0491 19.7792Z"
			fill="currentColor"
		/>
		<path
			d="M79.957 4.88287V0.943359H83.8965V4.88287H79.957ZM79.957 23.1031V6.85263H83.8965V23.1031H79.957Z"
			fill="currentColor"
		/>
		<path
			d="M77.5488 10.2997H73.1168V17.717C73.1168 19.5021 73.1476 19.656 74.8096 19.656H77.5488V23.1031H73.9786C70.7162 23.1031 69.1773 22.0259 69.1773 18.1171V10.2997H66.2227V6.85261H69.1773V2.42065H73.1168V6.85261H77.5488V10.2997Z"
			fill="currentColor"
		/>
		<path
			d="M59.3153 10.3305C59.5 10.3613 59.6539 10.1151 59.6847 10.0227C60.6695 7.77599 61.5621 6.60645 65.5324 6.60645V10.6075C60.9465 10.2997 59.6231 11.9309 59.6231 16.0551V23.1032H55.6836V6.85266H59.6231V7.74521C59.6231 8.45309 59.4384 9.06864 59.1614 9.62263C59.0383 9.89963 58.946 10.269 59.3153 10.3305Z"
			fill="currentColor"
		/>
		<path
			d="M54.0237 14.7625C54.0237 15.1933 53.9929 15.5011 53.9313 15.9628H42.1128C42.3898 18.425 43.9902 19.9023 46.4524 19.9023C48.3298 19.9023 49.2839 18.8866 49.7456 17.4401H53.7467C53.0388 20.7948 50.7613 23.3494 46.4216 23.3494C41.1587 23.3494 38.2656 19.81 38.2656 14.9779C38.2656 9.89963 41.4049 6.60645 46.3601 6.60645C50.1149 6.60645 54.0237 8.8532 54.0237 14.7625ZM42.2667 13.2544H50.0842C49.9303 11.2846 48.7607 9.8073 46.4832 9.8073C43.8056 9.8073 42.6668 11.3154 42.2667 13.2544Z"
			fill="currentColor"
		/>
		<path
			d="M21.2305 14.9779C21.2305 10.1458 24.4005 6.60645 29.448 6.60645C34.0031 6.60645 36.7115 9.68419 36.9885 13.0082H33.1106C32.8643 11.4693 31.664 10.1766 29.448 10.1766C26.7396 10.1766 25.17 12.1464 25.17 14.9779C25.17 17.8094 26.7396 19.7792 29.448 19.7792C31.664 19.7792 32.8643 18.4865 33.1106 16.9477H36.9885C36.7115 20.2716 34.0339 23.3494 29.448 23.3494C24.4929 23.3494 21.2305 19.81 21.2305 14.9779Z"
			fill="currentColor"
		/>
		<path
			d="M4.04688 18.6404C4.04688 15.0395 6.69373 13.716 9.95614 13.5314C12.8492 13.3775 15.4037 13.6545 15.373 12.1464C15.3422 11.0384 14.9113 9.8073 12.1106 9.8073C9.34059 9.8073 8.63271 10.9461 8.41727 12.2695H4.53931C5.09331 8.17609 8.60194 6.60645 12.2337 6.60645C15.9577 6.60645 19.3125 7.83754 19.3125 14.1161V23.1032H15.373V21.9644C15.373 21.2257 15.5576 20.7025 15.8346 20.1793C15.9577 19.9023 16.0809 19.5945 15.8039 19.4714C15.4961 19.3483 15.373 19.6561 15.3114 19.81C14.3881 22.0567 12.603 23.3494 9.70992 23.3494C6.66296 23.3494 4.04688 21.3796 4.04688 18.6404ZM15.373 14.7317C14.4496 15.7473 12.9723 15.9012 10.6332 16.1474C8.94049 16.3321 7.98639 16.9784 7.98639 18.425C7.98639 19.7792 9.27904 20.641 11.0949 20.3947C13.1878 20.1177 15.3422 18.7635 15.373 14.7317Z"
			fill="currentColor"
		/>
		<path
			d="M100.919 3.82949V3.44916H102.711V3.82949H102.043V5.63098H101.587V3.82949H100.919ZM102.976 3.44916H103.545L104.146 4.91507H104.171L104.772 3.44916H105.341V5.63098H104.894V4.21088H104.876L104.311 5.62033H104.006L103.442 4.20556H103.423V5.63098H102.976V3.44916Z"
			fill="currentColor"
		/>
	</svg>
);

export default LogoIcon;

import './App.css';
import LandingPageFooter from './components/LandingPageFooter';
import LandingPageHeader from './components/LandingPageHeader';
import LandingPageFeatures from './components/LandingPageFeatures';
import LandingPagePeople from './components/ArchitectCards/LandingPagePeople';
import LandingPageDescription from './components/LandingPageDescription';
import BenefitsSection from './components/BenefitsSection';
import {benefitsCompanies} from './components/BenefitsCompanies';
import specialistsImage from './images/specialists.jpg';
import companiesImage from './images/companies.jpg';
import {benefitsSpecialists} from './components/BenefitsSpecialists';
import LogoIcon from '../src/icons/Logo.svg';

function App() {
	return (
		<div className={` antialiased w-full flex flex-col items-center justify-center bg-white`}>
			<nav className="w-full flex justify-start border-b max-md:px-5 max-md:py-4 px-8 py-2.5">
				<img src={LogoIcon} alt="Logo" width={101} height={29} />
			</nav>
			<main className="w-full max-w-[1120px] mx-auto flex flex-col px-4 lg:px-0">
				<LandingPageHeader />
				<LandingPagePeople />
				<LandingPageDescription />
				<BenefitsSection
					title="Key Benefits for Specialists:"
					imageUrl={specialistsImage}
					benefits={benefitsSpecialists}
				/>
				<BenefitsSection
					title="Key Benefits for Companies:"
					imageUrl={companiesImage}
					benefits={benefitsCompanies}
				/>
				<LandingPageFeatures />
			</main>
			<LandingPageFooter />
		</div>
	);
}

export default App;
